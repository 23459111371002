import {
  ChartBarIcon,
  ChartPieIcon,
  DocumentCheckIcon,
} from '@heroicons/react/24/outline';
import {
  BarChart2,
  User,
  Ship,
  Banknote,
  BoxIcon,
  Building2,
  CircleSlash,
  Send,
  File,
  ShieldCheck,
  CreditCard,
  Pencil,
  Flag,
  Landmark,
  CircleDotIcon,
  RadiationIcon,
  ListOrdered,
  PiggyBank,
  PersonStanding,
  PersonStandingIcon,
  UserCheckIcon,
  StoreIcon,
  BarChart,
  PieChart,
  DollarSign,
  Truck,
  Boxes,
  Calculator,
  Book,
} from 'lucide-react';

const getHomeIcon = (icon: string, className) => {
  switch (icon) {
    case 'clients':
      return <UserCheckIcon className={`${className}`} />;
    case 'prices':
      return <DollarSign className={`${className}`} />;
    case 'banners':
      return <StoreIcon className={`${className}`} />;
    case 'orders':
      return <DocumentCheckIcon className={`${className}`} />;
    case 'dashboard':
      return <PieChart className={` ${className}`} />;
    case 'questionnaire':
      return <File className={` ${className}`} />;
    case 'questionnaires':
      return <File className={` ${className}`} />;
    case 'documents':
      return <File className={` ${className}`} />;
    case 'privileges':
      return <ShieldCheck className={` ${className}`} />;
    case 'lawyers':
      return <User className={` ${className}`} />;
    case 'payments':
      return <CreditCard className={` ${className}`} />;
    case 'users':
      return <User className={` ${className}`} />;
    case 'user':
      return <User className={` ${className}`} />;
    case 'firm':
      return <Pencil className={` ${className}`} />;
    case 'firms':
      return <Pencil className={` ${className}`} />;
    case 'country':
      return <Flag className={` ${className}`} />;
    case 'countries':
      return <Flag className={` ${className}`} />;
    case 'currency':
      return <Landmark className={` ${className}`} />;
    case 'currencies':
      return <Landmark className={` ${className}`} />;
    case 'taxes':
      return <CircleDotIcon className={` ${className}`} />;
    case 'variables':
      return <RadiationIcon className={` ${className}`} />;
    case 'paymentMethod':
      return <Banknote className={` ${className}`} />;
    case 'payment_methods':
      return <Banknote className={` ${className}`} />;
    case 'payment-methods':
      return <Banknote className={` ${className}`} />;
    case 'categories':
      return <ListOrdered className={` ${className}`} />;
    case 'promotions':
      return <PiggyBank className={` ${className}`} />;
    case 'freights':
      return <Boxes className={` ${className}`} />;
    case 'loads':
      return <Truck className={` ${className}`} />;
    case 'calculator':
      return <Calculator className={` ${className}`} />;
    case 'rate':
      return <Banknote className={` ${className}`} />;
    case 'tutorials':
      return <Book className={` ${className}`} />;
    case 'load':
      return <Truck className={` ${className}`} />;

    default:
      break;
  }
};

export default getHomeIcon;
