import {
  BarChart2,
  User,
  Ship,
  Banknote,
  BoxIcon,
  Building2,
  CircleSlash,
  Send,
} from 'lucide-react';

export const KEYS = {
  CATEGORY: 'category',
  CLIENT: 'client',
  COUNTRY: 'country',
  CURRENCY: 'currency',
  DOCUMENT: 'document',
  PRICE: 'price',
  LAWYER: 'lawyer',
  ORDER: 'orders',
  PAYMENT_METHOD: 'payment_methods',
  QUESTIONNAIRE: 'questionnaire',
  TAX: 'tax',
  USER: 'user',
  PROMOTION: 'promotion',
  GUIDE: 'guide',
  PAYMENT: 'payment',
  ADMIN: 'admin',
  BANNER: 'banner',
  CONTAINER: 'container',
  LOAD: 'loads',
  FREIGHT: 'freights',
  VESSEL: 'vessel',
  QUOTATION: 'quotations',
} as const;

export const OPTIONS = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  AUTH: 'auth',
} as const;

export const getRoute = (key) => {
  switch (key) {
    case 'user':
      return 'users';
    case 'lawyer':
      return 'lawyers';
    case 'price':
      return 'firms';
    case 'category':
      return 'categories';
    case 'banner':
      return 'banners';
    case 'client':
      return 'clients';
    case 'country':
      return 'countries';
    case 'currency':
      return 'currencies';
    case 'document':
      return 'documents';
    case 'order':
      return 'orders';
    case 'paymentMethod':
      return 'payment-methods';
    case 'questionnaire':
      return 'questionnaires';
    case 'tax':
      return 'taxes';
    case 'promotion':
      return 'promotions';
    case 'guide':
      return 'guides';
    case 'payment':
      return 'payments';
    case 'dashboard':
      return 'dashboard';
    case 'quotations':
      return 'quotations';

    default:
      break;
  }
};

export const routesArray = [
  { key: 'Fletes', route: 'freights' },
  { key: 'Calculadora', route: 'calculator' },
  { key: 'Contenedores', route: 'loads' },
  { key: 'Clientes', route: 'users' },
  { key: 'Envíos', route: 'orders' },
  { key: 'Métodos de pago', route: 'payment-methods' },
  { key: 'Tasa', route: 'rate' },
  { key: 'Tutoriales', route: 'tutorials' },
  { key: 'Pagos', route: 'payments' },
  { key: 'Roles y Privilegios', route: 'users' },
  { key: 'Cotizaciones', route: 'quotations' },
];
