/* eslint-disable no-nested-ternary */
import React from 'react';
import { useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import { Layout } from '../../components/layout';
import { useUser } from '../../hooks';
import { Auth, KEYS, OPTIONS } from '../../lib';
import Home from '../../components/home/Home';
import { FormHeader } from '../../components/forms';
import { DocumentForm } from '../../components/document';
import { DocumentModel } from '../../models';

function Index() {
  const [user] = useUser();
  const [isChineseUser, setIsChineseUser] = React.useState(false);

  // Detecta si el usuario es el usuario chino cuando el componente se monta y cuando el usuario cambia
  React.useEffect(() => {
    setIsChineseUser(user?.email === 'mou@mogosgroup.com');
  }, [user]);

  // Función para traducir textos
  const translate = (english: string, chinese: string) =>
    isChineseUser ? chinese : english;
  const [images, setImages] = React.useState<DocumentModel[]>(
    user?.profilePicture === '' || !user?.profilePicture
      ? []
      : [{ src: user?.profilePicture, name: user?.firstName }]
  );
  const updateURLs = React.useCallback(setImages, [setImages]);
  const animation = {
    initial: { opacity: 0, scale: 0.975 },
    animate: { opacity: 1, scale: 1 },
    transition: { duration: 0.6 },
  };
  return (
    <>
      {/* <Auth permissionKey={KEYS.ADMIN} permissionOption={OPTIONS.AUTH} /> */}
      <Layout
        title=""
        routesHistory={{
          text: translate('Roles y privilegios', '角色与权限'),
          href: `/app/Home`,
          key: 'Home',
        }}
      >
        <motion.div className="bg-transparent p-4 h-full" {...animation}>
          <div className="w-full max-w-7xl gap-6 -mt-12 flex flex-col">
            <div className="flex flex-row gap-1 items-center">
              <img
                alt=""
                src="/media/logos/small.png"
                className=" h-7 w-7 flex items-end"
              />
              <h1 className=" text-font-black ">
                {translate(
                  `¡Hola, ${user?.firstName}!`,
                  `你好, ${user?.firstName}！`
                )}
              </h1>
            </div>
            <p>
              {translate(
                '¡Bienvenido a tu plataforma administrativa!',
                '欢迎来到你的管理平台！'
              )}
            </p>
            <div className="w-full md:h-50 h-32 shadow-lg rounded-lg flex flex-col items-center justify-center bg-primary-600 bg-gradient-to-r from-primary-900 to-primary-300 relative">
              <img
                alt=""
                src="/media/logos/white.png"
                className=" h-6 md:h-50 flex bottom-0"
              />
              {/* <h1>Bienvenido a la plataforma administrativa</h1> */}
              <img
                alt=""
                src="/media/img/waves.png"
                className="absolute h-24 flex items-end w-full bottom-0"
              />
            </div>
            <div className="mt-10">
              <Home permissions={user?.permissions} />
            </div>
          </div>
        </motion.div>
      </Layout>
    </>
  );
}

export default Index;
