import React from 'react';
import { useRouter } from 'next/router';
import { Permission } from '../../models';
import { useNotify, useUser } from '../../hooks';
import { KEYS, OPTIONS, getRoute, routesArray } from '../../lib';
import Button from '../common/button/Button';

interface HomeIndexProps {
  permissions?: Permission[];
}

function HomeIndex({ permissions }: HomeIndexProps) {
  const router = useRouter();
  const [currentUser] = useUser();
  const [isChineseUser, setIsChineseUser] = React.useState(
    currentUser?.email === 'mou@mogosgroup.com'
  );

  // Actualiza el estado en función del correo electrónico del usuario
  React.useEffect(() => {
    setIsChineseUser(currentUser?.email === 'mou@mogosgroup.com');
  }, [currentUser]);

  // Traduce el nombre de la ruta si el usuario es chino
  const translateRouteName = (name: string) => {
    if (isChineseUser && name === 'orders') {
      return '阅读订单'; // Traducción de 'Leer ordenes' en chino
    }
    return name;
  };
  const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);
  const notify = useNotify();
  const defaultpermission = {
    name: 'Todos',
    abbreviation: 'ALL',
    paymentMethods: [],
  };

  React.useEffect(() => {
    if (currentUser?.permissions?.length > 0) {
      const superAdmin = currentUser?.permissions.find(
        (permission) => permission.key === 'superadmin'
      );
      if (superAdmin) {
        setIsSuperAdmin(true);
      }
    }
  }, [currentUser]);

  return (
    <>
      <div className="flex flex-wrap w-full gap-4 ">
        {isSuperAdmin
          ? routesArray?.map((route) => (
              <Button
                buttonType="home"
                withIcon={route?.route}
                iconClassName=" text-primary-300 w-10 h-10"
                className="text-neutral-600 text-xs"
                onClick={(e) => {
                  e.preventDefault();
                  router.push(`/app/${route?.route}`);
                }}
                key={route?.route}
              >
                {translateRouteName(route.key)}
              </Button>
            ))
          : null}
      </div>
      <div className="flex flex-wrap w-full gap-9 ">
        {!isSuperAdmin
          ? permissions?.map((permission) =>
              permission?.option === OPTIONS.READ ? (
                <Button
                  buttonType="home"
                  withIcon={permission?.key}
                  iconClassName=" text-primary-800 w-9 h-9"
                  className="text-neutral-600 text-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    router.push(`/app/${permission?.key}`);
                  }}
                  key={permission._id}
                >
                  {translateRouteName(permission.name)}
                </Button>
              ) : null
            )
          : null}
      </div>
    </>
  );
}

export default HomeIndex;
